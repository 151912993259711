import React, { useContext, useEffect, useRef, useState } from 'react'
import './EventContentDialog.scss'

import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Toast } from 'primereact/toast'
import EventsContentDialogEdit from '../EventsContentDialogEdit/EventsContentDialogEdit.component'
import { isAdmin } from '../../../services/roles.sevice'
import { getContest, updateContest } from '../../../services/events.service'
import { UserContext } from '../../../context/UserProvider'

export const EventContentDialog = ({
  isModalVisible,
  onModalHide = () => {},
  id,
}) => {
  const user = useContext(UserContext)
  const toast = useRef(null)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState({
    title: '',
    id,
    date: '',
    max_points: 0,
    description: '',
  })

  const fetchContest = async () => {
    const contest = await getContest(id)
    setSelectedEvent({
      id: contest.id,
      title: contest.title,
      description: contest.description,
      max_points: contest.max_points,
      date: new Date(
        moment.parseZone(contest.date).format('DD MMMM YYYY, HH:mm')
      ),
    })
  }

  useEffect(() => {
    fetchContest()
  }, [])
  const hideEditDialog = () => {
    setOpenEditDialog(false)
  }
  const handleEditButton = async () => {
    if (selectedEvent.id) {
      await updateContest(selectedEvent.id, {
        title: selectedEvent.title,
        date: moment(selectedEvent.date)
          .format('DD MMMM YYYY, HH:mm')
          .toString(),
        max_points: selectedEvent.max_points,
        description: selectedEvent.description,
      })
        .then(() => {
          toast.current.show({
            severity: 'success',
            summary: 'Update Succesfully',
            detail: 'Event updated Succesfully',
          })
        })
        .catch((err) => {
          toast.current.show({
            severity: 'error',
            summary: 'Update Failed',
            detail: err.message,
          })
        })
    }
    await fetchContest()
    setOpenEditDialog(false)
  }

  return (
    <div className="event-content-form-dialog">
      {openEditDialog && (
        <EventsContentDialogEdit
          onEditModalHide={hideEditDialog}
          isEditModalVisible={openEditDialog}
          selectedEvent={selectedEvent}
          title={selectedEvent.title}
          id={id}
          date={selectedEvent.date}
          description={selectedEvent.description}
          maxPoints={selectedEvent.max_points}
          editEvent={setSelectedEvent}
          onEditButton={handleEditButton}
        />
      )}
      <Toast ref={toast} />
      <Dialog
        header={selectedEvent.title}
        visible={isModalVisible}
        onHide={onModalHide}
        draggable={false}
        contentClassName="event-content-form-dialog__content"
      >
        <hr />
        <div className="event-content-form-dialog__dialog-content">
          <div className="event-content-form-dialog__dialog-content-info">
            <h2> Hour </h2>
            <span>{moment(selectedEvent.date).format('HH:mm').toString()}</span>
          </div>
          <div className="event-content-form-dialog__dialog-content-info">
            <h2> Date </h2>
            <span>
              {moment(selectedEvent.date).format('DD/MM/YYYY').toString()}
            </span>
          </div>
          <div className="event-content-form-dialog__dialog-content-info">
            <h2> Points </h2> <span> {selectedEvent.max_points} </span>
          </div>
        </div>
        <div className="event-content-form-dialog__info">
          <p className="event-content-form-dialog__description-content">
            {selectedEvent.description}
          </p>
        </div>
        {isAdmin(user) && (
          <Button
            label="Edit event"
            onClick={() => setOpenEditDialog(true)}
            className="event-content-form-dialog__dialog-content-button"
            onChange={(e) => selectedEvent({ id: e.target.value })}
          />
        )}
      </Dialog>
    </div>
  )
}
EventContentDialog.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  onModalHide: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
}
export default { EventContentDialog }
