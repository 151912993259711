import './newParticipant.scss'
import React, { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import PropTypes from 'prop-types'
import { Dropdown } from 'primereact/dropdown'
import { InputNumber } from 'primereact/inputnumber'
import { Button } from 'primereact/button'
import { getUsers } from '../../../services/user.service'
import { getContests } from '../../../services/events.service'
import { TRIVIA_ID } from '../../../constants/trivia'

const NewParticipant = ({
  isModalVisible,
  onModalHide = () => {},
  onParticipationAdd = () => {},
  onParticipationDelete = () => {},
  selectedParticipation,
  setParticipation = () => {},
  isEdit = false,
}) => {
  const [users, setUsers] = useState(null)
  const [contests, setContests] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      if (!isEdit) {
        const data = await getUsers()
        setUsers(data)
      }
      const events = await getContests()
      setContests(events.filter((x) => x.id !== TRIVIA_ID))
    }
    fetchData()
  }, [])

  return (
    <Dialog
      position="top"
      visible={isModalVisible}
      onHide={() => onModalHide()}
      draggable={false}
    >
      <div className="new-participant">
        <Dropdown
          className="new-participant__dropdown"
          optionLabel="title"
          optionValue="id"
          filter
          value={selectedParticipation.contest_id}
          options={contests}
          onChange={(e) =>
            setParticipation({
              id: selectedParticipation.id,
              points: selectedParticipation.points,
              contest_id: e.value,
              participant_id: selectedParticipation.participant_id,
            })
          }
          placeholder="Select a Contest"
        />
        <Dropdown
          className="new-participant__dropdown"
          disabled={isEdit}
          optionLabel="name"
          optionValue="id"
          value={selectedParticipation.participant_id}
          options={users}
          filter
          onChange={(e) =>
            setParticipation({
              id: selectedParticipation.id,
              points: selectedParticipation.points,
              contest_id: selectedParticipation.contest_id,
              participant_id: e.value,
            })
          }
          placeholder="Select a User"
        />
        <InputNumber
          className="new-participant__input"
          placeholder="Enter points"
          min={0}
          max={100000}
          format={false}
          useGrouping={false}
          value={selectedParticipation.points}
          onValueChange={(e) =>
            setParticipation({
              id: selectedParticipation.id,
              points: e.target.value,
              contest_id: selectedParticipation.contest_id,
              participant_id: selectedParticipation.participant_id,
            })
          }
        />
        <div
          className={
            isEdit
              ? 'new-participant__button new-participant__button--delete'
              : 'new-participant__button'
          }
        >
          <Button onClick={() => onParticipationAdd()}>
            {isEdit ? 'Update Points' : 'Add Points'}
          </Button>
          {isEdit && (
            <Button onClick={() => onParticipationDelete()}>Delete</Button>
          )}
        </div>
      </div>
    </Dialog>
  )
}

NewParticipant.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  onModalHide: PropTypes.func.isRequired,
  onParticipationAdd: PropTypes.func.isRequired,
  onParticipationDelete: PropTypes.func,
  selectedParticipation: PropTypes.shape({
    id: PropTypes.number,
    points: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    contest_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    participant_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  setParticipation: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
}
NewParticipant.defaultProps = {
  onParticipationDelete: () => {},
  isEdit: false,
}
export { NewParticipant }
