import React, { useEffect, useState } from 'react'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { loginRequest } from '../../constants/authConfig'
import { setAuthToken } from '../../services/localStorage.service'
import { getUserProfile } from '../../services/user.service'
import { Routing } from '../Routing'
import { UserContext } from '../../context/UserProvider'

const App = () => {
  const isAuthenticated = useIsAuthenticated()
  const [user, setUser] = useState({ role: null })
  const { instance, accounts } = useMsal()

  useEffect(() => {
    if (!isAuthenticated) return

    const request = {
      ...loginRequest,
      account: accounts[0],
    }

    const handleTokenResponse = (tokenData) => {
      if (tokenData) {
        setAuthToken(tokenData)

        getUserProfile().then((data) => {
          setUser(data)
        })
      }
    }

    instance
      .acquireTokenSilent(request)
      .then(handleTokenResponse)
      .catch(() => {
        instance.acquireTokenPopup(request).then(handleTokenResponse)
      })
  }, [isAuthenticated])

  return (
    <UserContext.Provider value={user}>
      <Routing />
    </UserContext.Provider>
  )
}

export { App }
