import { methods } from '../constants/apiMethods'
import api from './api.helper'

const url = 'purchases'

export const getPurchases = () =>
  api(methods.get, url).then(({ data: purchases }) => purchases)

export const addPurchases = (data) => api(methods.post, url, data)

export const getUserPurchases = (userId) =>
  api(methods.get, url).then(({ data: purchases }) =>
    purchases.filter((x) => x.user_id === userId)
  )
