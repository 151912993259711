import './Navbar.scss'

import { Button } from 'primereact/button'
import { Sidebar } from 'primereact/sidebar'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router'
import { routes } from '../../../constants/routes'
import { UserContext } from '../../../context/UserProvider'
import { LogOutButton } from '../../../pages/authPage/LogOutButton.component'
import { isAdmin, isTriviaAdmin, isUser } from '../../../services/roles.sevice'
import { DevQuizMenu } from '../devQuizMenu'

const Navbar = () => {
  const navigate = useNavigate()
  const user = useContext(UserContext)
  const [isMobileBarVisible, setisMobileBarVisible] = useState(false)

  return (
    <>
      <Sidebar
        fullScreen
        visible={isMobileBarVisible}
        onHide={() => setisMobileBarVisible(false)}
      >
        Content
      </Sidebar>
      <div className="navbar">
        {isUser(user) && (
          <Button
            label="Dashboard"
            className="p-button-text"
            onClick={() => navigate(routes.dashboard)}
          />
        )}
        <Button
          label="Participants"
          className="p-button-text"
          onClick={() => navigate(routes.participants)}
        />
        <Button
          label="Event details"
          className="p-button-text"
          onClick={() => navigate(routes.events)}
        />
        {(isAdmin(user) || isTriviaAdmin(user)) && <DevQuizMenu />}
        {isUser(user) && (
          <Button
            label="DevQuiz"
            className="p-button-text"
            onClick={() => navigate(routes.devQuiz)}
          />
        )}
        <Button
          label="Prizes"
          className="p-button-text"
          onClick={() => navigate(routes.prizes)}
        />
        <LogOutButton className="p-button-text" />
      </div>
    </>
  )
}

export { Navbar }
