import './dashboard.scss'

import React, { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import { AppLayout } from '../../components/AppLayout/AppLayout.component'

import {
  getUserParticipations,
  getUserProfile,
} from '../../services/user.service'

const Dashboard = () => {
  const [currentUser, setCurrentUser] = useState([])
  const [userParticipations, setUserParticipations] = useState([])

  useEffect(() => {
    getUserProfile().then((fetchProfile) => {
      setCurrentUser(fetchProfile)
      getUserParticipations(fetchProfile.id).then((fetchedParticipations) => {
        const mapParticipations = fetchedParticipations.map((item) => {
          return {
            id: item.id,
            title: item.contest.title,
            points: item.points,
            addedBy: item.added_by.name,
          }
        })
        setUserParticipations(mapParticipations)
      })
    })
  }, [])

  return (
    <AppLayout>
      <div className="dashboard">
        <div className="dashboard__container">
          <span className="dashboard__container-username">
            Welcome {currentUser.name}!
          </span>
          <div className="dashboard__points">
            <span className="dashboard__user-points">
              Total points: {currentUser.total_points}
            </span>
            <span className="dashboard__user-points">
              Purchase points: {currentUser.points_left}
            </span>
          </div>
          <DataTable
            className="dashboard__table"
            value={userParticipations}
            breakpoint="450px"
            metaKeySelection
          >
            <Column field="title" header="Event" />
            <Column field="points" header="Score" />
            <Column field="addedBy" header="Added by" />
          </DataTable>
        </div>
      </div>
    </AppLayout>
  )
}

export { Dashboard }
