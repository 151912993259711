import './devquiz.scss'

import debounce from 'lodash/debounce'
import React, { useEffect, useRef, useState } from 'react'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { Knob } from 'primereact/knob'
import { Divider } from 'primereact/divider'
import { Toast } from 'primereact/toast'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { AppLayout } from '../../components/AppLayout/AppLayout.component'
import { getDifficulties } from '../../services/difficulties.service'
import { getCategories } from '../../services/categories.service'
import { addTrivia, getTrivia } from '../../services/devquiz.service'
import { Answer } from './devQuizAnswer'
import { TRIVIA_TIMER_DURATION_SEC } from '../../constants/trivia'

const DevQuiz = () => {
  const toast = useRef(null)
  const [questionIncrement, setQuestionIncrement] = useState(0)
  const [difficulties, setDifficulties] = useState([])
  const [categories, setCategories] = useState([])
  const [selectedDifficulty, setSelectedDifficulty] = useState(null)
  const [question, setQuestion] = useState(null)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const isSubmittedRef = useRef(false)
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(false)
  const [checkedAnswer, setCheckedAnswer] = useState(null)
  const [secondsLeft, setSecondsLeft] = useState(0)
  const [isTimerRunning, setIsTimerRunning] = useState(false)
  const answerTimeoutInterval = useRef(null)

  const startTimer = () => {
    setSecondsLeft(0)
    setIsTimerRunning(true)
  }

  const stopTimer = () => {
    setSecondsLeft(0)
    setIsTimerRunning(false)
  }

  const fetchData = async () => {
    const fetchedDifficulties = await getDifficulties()
    setDifficulties(fetchedDifficulties)
    const fetchedCategories = await getCategories()
    setCategories(fetchedCategories)
  }

  const resetEverything = async () => {
    setSelectedDifficulty(null)
    setQuestion(null)
    setIsSubmitted(false)
    isSubmittedRef.current = false
    setIsCorrectAnswer(false)
  }

  const handleAskQuestionClick = async () => {
    const fetchedQuestion = await getTrivia({
      difficulty_id: selectedDifficulty,
      category_id: categories[Math.floor(Math.random() * categories.length)].id,
    }).catch((err) => {
      if (err.response.status === 404) {
        if (questionIncrement < 5) {
          setQuestionIncrement(questionIncrement + 1)
          handleAskQuestionClick()

          return null
        }

        toast.current.show({
          severity: 'error',
          summary: 'Operation Failed',
          detail: "Can't find a question with those characteristics",
        })
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Operation Failed',
          detail: err?.response?.data?.message || err.message,
        })
      }

      return null
    })
    setQuestion(fetchedQuestion)
    setQuestionIncrement(0)

    if (fetchedQuestion) {
      setCheckedAnswer(fetchedQuestion.answers[0])
      startTimer()
    }
  }

  const submitAnswer = debounce(async () => {
    if (!question || isSubmitted || isSubmittedRef.current) {
      return
    }

    setIsSubmitted(true)
    isSubmittedRef.current = true
    stopTimer()

    await addTrivia({
      question_id: question?.id || 0,
      answer_id: checkedAnswer?.id || 0,
    })
      .then((response) => {
        const points = response?.data?.points || 0
        const isCorrect = points > 0
        const isSingular = points === 1

        setIsCorrectAnswer(isCorrect)

        toast.current.show({
          severity: 'info',
          summary: 'Your answer was registered',
          detail: `You received ${response.data.points} ${
            isSingular ? 'point' : 'points'
          }`,
          sticky: true,
        })
      })
      .catch((err) => {
        toast.current.show({
          severity: 'error',
          summary: 'Added Failed',
          detail: err.message,
          sticky: true,
        })
      })
  }, 500)

  const handleSubmitAnswer = async () => {
    confirmDialog({
      message: 'Are you sure you selected the correct options?',
      header: 'Answer Confirmation',
      icon: 'pi pi-info-circle',
      accept: async () => {
        await submitAnswer()
      },
    })
  }

  const handleContinueClick = async () => {
    resetEverything()
  }

  useEffect(() => {
    if (isTimerRunning) {
      answerTimeoutInterval.current = setInterval(() => {
        setSecondsLeft((prevSeconds) => {
          if (prevSeconds >= TRIVIA_TIMER_DURATION_SEC) {
            submitAnswer()
          }

          return prevSeconds + 1
        })
      }, 1000)
    } else {
      clearInterval(answerTimeoutInterval.current)
    }

    return () => {
      clearInterval(answerTimeoutInterval.current)
    }
  }, [isTimerRunning])

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(
    () => () => {
      submitAnswer()
    },
    []
  )

  useEffect(() => {
    const removeListeners = () => {
      window.removeEventListener('beforeunload', submitAnswer)
      window.removeEventListener('blur', submitAnswer)
      document.removeEventListener('visibilitychange', submitAnswer)
    }

    if (!question) {
      removeListeners()

      return
    }

    window.addEventListener('beforeunload', submitAnswer)
    window.addEventListener('blur', submitAnswer)
    document.addEventListener('visibilitychange', submitAnswer)

    // eslint-disable-next-line consistent-return
    return () => {
      removeListeners()
    }
  }, [question])

  return (
    <AppLayout>
      <ConfirmDialog />
      <Toast ref={toast} />
      <div className="devquiz">
        <div className="devquiz__container">
          <div className="controlls">
            <p>
              You can submit up to 5 answers daily. While you answer a question
              you are not allowed to leave the page. Carefully, answering each
              question is time-limited to <strong>30 seconds</strong>. Good
              luck!
            </p>
            <div className="controlls__fields">
              <Dropdown
                optionLabel="name"
                optionValue="id"
                disabled={question}
                className="controlls__dropdown"
                value={selectedDifficulty}
                options={difficulties}
                onChange={(e) => setSelectedDifficulty(e.value)}
                placeholder="Select a Difficulty"
              />
            </div>
            <div className="controlls__ask-button">
              <Button
                disabled={!selectedDifficulty || question}
                onClick={() => handleAskQuestionClick()}
              >
                Ask Question
              </Button>
            </div>
          </div>
          {question && (
            <div>
              <div className="wrapper">
                <Card title="Question" className="wrapper__card">
                  {question.description}
                  <Divider />
                  <ol type="A">
                    {question.answers.map((answer) => (
                      <Answer
                        key={answer.id}
                        answer={answer}
                        isSubmitted={isSubmitted}
                        checkedAnswer={checkedAnswer}
                        setCheckedAnswer={setCheckedAnswer}
                        isCorrect={
                          isCorrectAnswer && checkedAnswer.id === answer.id
                        }
                      />
                    ))}
                  </ol>
                </Card>
                <div className="wrapper__verify-button">
                  <Button
                    disabled={!checkedAnswer || isSubmitted}
                    onClick={() => handleSubmitAnswer()}
                  >
                    Submit answer
                  </Button>
                </div>
              </div>

              {!isSubmitted && (
                <div className="timer-container">
                  <Knob
                    value={secondsLeft}
                    step={1}
                    min={0}
                    max={TRIVIA_TIMER_DURATION_SEC}
                    readOnly
                  />
                </div>
              )}
            </div>
          )}
          {isSubmitted && (
            <div className="validation-buttons">
              <Button
                onClick={handleContinueClick}
                className="p-button-rounded"
              >
                Continue
              </Button>
            </div>
          )}
        </div>
      </div>
    </AppLayout>
  )
}

export { DevQuiz }
