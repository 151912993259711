import axios from 'axios'
import { methods } from '../constants/apiMethods'
import { getAuthToken } from './localStorage.service'

const http = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/v1`,
})

export default async function api(
  method,
  url,
  params = {},
  config = {
    headers: {
      accessToken: getAuthToken().accessToken,
      AUD: getAuthToken().account.idTokenClaims.aud,
      ISS: getAuthToken().account.idTokenClaims.iss,
    },
  }
) {
  const body = method === methods.get ? 'params' : 'data'
  const requestConfig = {
    method,
    url,
    [body]: params,
    ...config,
  }

  return http.request(requestConfig)
}
