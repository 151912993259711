import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react'
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
import { routes } from '../../constants/routes'
import { UserContext } from '../../context/UserProvider'

import { isAdmin, isUser } from '../../services/roles.sevice'

const RequireUser = ({ children }) => {
  const user = useContext(UserContext)

  return (
    <>
      <AuthenticatedTemplate>
        {isUser(user) && { ...children }}
        {isAdmin(user) && <Navigate to={routes.participants} replace />}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Navigate to={routes.auth} replace />
      </UnauthenticatedTemplate>
    </>
  )
}

RequireUser.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}
export { RequireUser }
