import './participant.scss'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import PropTypes from 'prop-types'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toast } from 'primereact/toast'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'

import { NewParticipant } from '../newParticipant/newParticipant.component'
import {
  deleteParticipation,
  updateParticipation,
} from '../../../services/participations.service'
import { getUserParticipations } from '../../../services/user.service'
import { isAdmin } from '../../../services/roles.sevice'
import { UserContext } from '../../../context/UserProvider'

const Participant = ({ isModalVisible, onModalHide = () => {}, Id }) => {
  const toast = useRef(null)
  const user = useContext(UserContext)
  const [events, setEvents] = useState([])
  const [isNewModalVisible, setIsNewModalVisible] = useState(false)
  const [selectedParticipation, setSelectedParticipation] = useState({
    id: null,
    points: '',
    contest_id: '',
    participant_id: '',
  })

  const fetchUserParticipations = async () => {
    const participations = await getUserParticipations(Id)
    const mapParticipations = participations.map((x) => {
      return {
        id: x.id,
        title: x.contest.title,
        points: x.points,
        addedBy: x.added_by.name,
        participant_id: x.participant_id,
        contest_id: x.contest_id,
      }
    })
    setEvents(mapParticipations)
  }

  useEffect(() => {
    fetchUserParticipations()
  }, [])

  const setNewFalse = () => {
    setIsNewModalVisible(false)
    setSelectedParticipation({
      id: null,
      points: '',
      contest_id: '',
      participant_id: '',
    })
  }

  const handleParticipationUpdate = async () => {
    await updateParticipation(selectedParticipation.id, {
      points: selectedParticipation.points,
      contest_id: selectedParticipation.contest_id,
      participant_id: selectedParticipation.participant_id,
    })
      .then(() =>
        toast.current.show({
          severity: 'success',
          summary: 'Update Succesfully',
          detail: 'Participation updated Succesfuly',
        })
      )
      .catch((err) => {
        toast.current.show({
          severity: 'error',
          summary: 'Update Failed',
          detail: err.message,
        })
      })
    await fetchUserParticipations()
    setNewFalse()
  }

  const handleParticipationDelete = async () => {
    await deleteParticipation(selectedParticipation.id)
      .then(() => {
        toast.current.show({
          severity: 'success',
          summary: 'Deleted Succesfully',
          detail: 'Participation deleted Succesfuly',
        })
      })
      .catch((err) => {
        toast.current.show({
          severity: 'error',
          summary: 'Delete Failed',
          detail: err.message,
        })
      })
    await fetchUserParticipations()
    setNewFalse()
  }

  const handleDeleteConfirm = () => {
    confirmDialog({
      message: 'Do you want to delete this?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => handleParticipationDelete(selectedParticipation.id),
    })
  }

  return (
    <>
      <ConfirmDialog />
      <Toast ref={toast} />
      {isNewModalVisible && (
        <NewParticipant
          isModalVisible={isNewModalVisible}
          onModalHide={setNewFalse}
          selectedParticipation={selectedParticipation}
          setParticipation={setSelectedParticipation}
          onParticipationAdd={handleParticipationUpdate}
          onParticipationDelete={handleDeleteConfirm}
          isEdit
        />
      )}
      <Dialog
        contentClassName="participant"
        visible={isModalVisible}
        onHide={onModalHide}
        draggable={false}
      >
        <DataTable
          className="participant__table"
          value={events}
          breakpoint="360px"
          metaKeySelection
          selectionMode={isAdmin(user) && 'single'}
          onSelectionChange={(e) => {
            setSelectedParticipation({
              id: e.value.id,
              points: e.value.points,
              contest_id: e.value.contest_id,
              participant_id: e.value.participant_id,
            })
            setIsNewModalVisible(true)
          }}
        >
          <Column field="title" header="Event" />
          <Column field="points" header="Score" />
          <Column field="addedBy" header="Added by" />
        </DataTable>
      </Dialog>
    </>
  )
}

Participant.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  onModalHide: PropTypes.func.isRequired,
  Id: PropTypes.number.isRequired,
}

export { Participant }
