import React from 'react'
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom'

import { routes } from '../../constants/routes'
import { Auth } from '../../pages/authPage'
import { Categories } from '../../pages/categoriesPage'
import { Dashboard } from '../../pages/dashboardPage/dashboard.component'
import { DevQuiz } from '../../pages/devQuizPage/devQuiz.component'
import { Difficulties } from '../../pages/difficultiesPage'
import { Events } from '../../pages/eventsPage/events.component'
import { Participants } from '../../pages/participantsPage'
import { Prizes } from '../../pages/prizesPage/prizes.component'
import { Questions } from '../../pages/questionsPage'
import { RequireAuth } from '../RequireAuth'
import { RequireTrivia } from '../RequireTrivia/RequireTrivia.component'
import { RequireUser } from '../RequireUser/RequireUser.component'

const Routing = () => (
  <Router>
    <Routes>
      <Route path={routes.auth} element={<Auth />} />
      <Route
        exact
        path={routes.dashboard}
        element={
          <RequireUser>
            <Dashboard />
          </RequireUser>
        }
      />
      <Route
        path={routes.events}
        element={
          <RequireAuth>
            <Events />
          </RequireAuth>
        }
      />
      <Route
        path={routes.participants}
        element={
          <RequireAuth>
            <Participants />
          </RequireAuth>
        }
      />
      <Route
        path={routes.prizes}
        element={
          <RequireAuth>
            <Prizes />
          </RequireAuth>
        }
      />
      <Route
        path={routes.difficulty}
        element={
          <RequireAuth>
            <Difficulties />
          </RequireAuth>
        }
      />
      <Route
        path={routes.categories}
        element={
          <RequireAuth>
            <Categories />
          </RequireAuth>
        }
      />
      <Route
        path={routes.questions}
        element={
          <RequireAuth>
            <Questions />
          </RequireAuth>
        }
      />
      <Route
        path={routes.devQuiz}
        element={
          <RequireTrivia>
            <DevQuiz />
          </RequireTrivia>
        }
      />
      <Route
        exact
        path="*"
        element={<Navigate to={routes.dashboard} replace />}
      />
    </Routes>
  </Router>
)

export { Routing }
