import './devquizAnswer.scss'
import { RadioButton } from 'primereact/radiobutton'
import React from 'react'
import PropTypes from 'prop-types'

const Answer = ({
  answer,
  isSubmitted,
  checkedAnswer,
  setCheckedAnswer,
  isCorrect,
}) => (
  <li
    className={`devQuiz-answer ${
      isCorrect && isSubmitted && 'devQuiz-answer--correct'
    } ${
      !isCorrect &&
      answer === checkedAnswer &&
      isSubmitted &&
      'devQuiz-answer--wrong'
    }`}
  >
    <RadioButton
      value={answer}
      disabled={isSubmitted}
      name="answer"
      onChange={(e) => setCheckedAnswer(e.value)}
      checked={checkedAnswer === answer}
    />
    <label className="devQuiz-answer__label">{answer.description}</label>
  </li>
)

Answer.propTypes = {
  answer: PropTypes.shape({
    description: PropTypes.string.isRequired,
  }).isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  checkedAnswer: PropTypes.shape({
    correct: PropTypes.bool,
    description: PropTypes.string.isRequired,
  }),
  setCheckedAnswer: PropTypes.func.isRequired,
  isCorrect: PropTypes.bool,
}

Answer.defaultProps = {
  checkedAnswer: null,
  isCorrect: false,
}

export { Answer }
