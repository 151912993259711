import { userRoles } from '../constants/userRoles'

export const isAdmin = (user) => user?.role === userRoles.admin

export const isTriviaAdmin = (user) => user?.role === userRoles.devQuiz

export const isUser = (user) =>
  user?.role === userRoles.admin ||
  userRoles.user ||
  user?.role === userRoles.devQuiz
