import './UsersRedeemsAdmin.scss'
import React, { useState, useEffect } from 'react'

import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { MultiSelect } from 'primereact/multiselect'

import { getPurchases } from '../../../services/purchases.service'
import { getItems } from '../../../services/prizes.service'

const UsersRedeemsAdmin = () => {
  const [purchases, setPurchases] = useState([])
  const [searchUser, setSearchUser] = useState('')
  const [prizes, setPrizes] = useState(null)
  const [selectedPrize, setSelectedPrize] = useState([])

  const filteredParticipant = purchases
    .filter((x) => x.user.name.toLowerCase().includes(searchUser.toLowerCase()))
    .filter((x) =>
      selectedPrize.length > 0 ? selectedPrize.indexOf(x.item.title) > -1 : x
    )

  const fetchAditionalData = async () => {
    const fetchedItems = await getItems()
    setPrizes(fetchedItems)
  }

  const fetchPurchases = async () => {
    const servPurchases = await getPurchases()
    setPurchases(servPurchases)
  }

  useEffect(() => {
    fetchPurchases()
    fetchAditionalData()
  }, [])

  return (
    <div className="user-redeems-admin">
      <div className="user-redeems-admin__container">
        <div className="user-redeems-admin__filters-tab">
          <InputText
            className="user-redeems-admin__search-input"
            type="text"
            placeholder="Search user"
            value={searchUser}
            onChange={(e) => setSearchUser(e.target.value)}
          />
          <MultiSelect
            className="user-redeems-admin__filter-select"
            value={selectedPrize}
            onChange={(e) => setSelectedPrize(e.value)}
            optionLabel="title"
            optionValue="title"
            options={prizes}
            placeholder="Filter By Prize"
          />
        </div>
        <div className="user-redeems-admin__table">
          <DataTable
            metaKeySelection
            selectionMode="single"
            value={filteredParticipant}
            breakpoint="500px"
            sortMode="multiple"
            onChange={(e) => purchases(e.target.value)}
          >
            <Column field="user.name" header="User name" sortable />
            <Column field="item.title" header="Prize redeemed" sortable />
            <Column field="quantity" header="Quantity" sortable />
          </DataTable>
        </div>
      </div>
    </div>
  )
}

export default UsersRedeemsAdmin
