import './PrizesContent.scss'
import React, { useContext, useState } from 'react'

import PropTypes from 'prop-types'
import { Button } from 'primereact/button'
import { InputNumber } from 'primereact/inputnumber'
import { Image } from 'primereact/image'

import { isAdmin, isUser } from '../../../services/roles.sevice'

import assist from '../../../assets/images/ASSIST30.png'
import { UserContext } from '../../../context/UserProvider'

const PrizesContent = ({
  prize,
  currentUserPoints,
  handleDeletePrize = () => {},
  onAddPurchase = () => {},
  onEditPrize = () => {},
}) => {
  const user = useContext(UserContext)
  const [quantityValue, setQuantityValue] = useState(0)

  return (
    <div className="prize-container">
      <div className="prize-container__card">
        <div className="prize-container__card-image">
          <Image
            src={
              prize.image
                ? `https://dev-week-api.assistcloud.services/${prize.image}`
                : assist
            }
            alt="Image"
            preview
            imageClassName="prize-container__card-image-style"
          />
        </div>
        <div className="prize-container__card-content">
          <h2> {prize.title} </h2>
          <div className="prize-container__card-content-description">
            <span> {prize.description} </span>
          </div>
          <div className="prize-container__card-content-points">
            {isUser(user) &&
              (quantityValue * prize.points <= currentUserPoints ? (
                <h4>Price: {quantityValue * prize.points} points</h4>
              ) : (
                <h4 className="prize-container__card-content-points-conditional">
                  Price: {quantityValue * prize.points} points
                </h4>
              ))}
            {isAdmin(user) && <h4>Points: {prize.points} points</h4>}

            {isUser(user) && (
              <InputNumber
                mode="decimal"
                showButtons
                min={1}
                max={100}
                className="prize-container__card-content-quantity"
                placeholder="Quantity"
                value={quantityValue}
                onValueChange={(e) => setQuantityValue(e.value)}
              />
            )}
          </div>
          {isUser(user) && (
            <Button
              label="Redeem"
              className="p-button-raised"
              disabled={
                quantityValue * prize.points > currentUserPoints ||
                !quantityValue
              }
              onClick={() =>
                onAddPurchase({
                  item_title: prize.title,
                  item_id: prize.id,
                  quantity: quantityValue,
                  item_points: quantityValue * prize.points,
                })
              }
            />
          )}
          {isAdmin(user) && (
            <div className="prize-container__card-content-buttons">
              <Button
                label="Delete"
                className="p-button-raised"
                onClick={() => handleDeletePrize(prize.id)}
              />

              <Button
                label="Edit"
                className="p-button-raised"
                onClick={() => onEditPrize(prize.id)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

PrizesContent.propTypes = {
  prize: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    points: PropTypes.number,
    description: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
  currentUserPoints: PropTypes.number.isRequired,
  handleDeletePrize: PropTypes.func.isRequired,
  onEditPrize: PropTypes.func.isRequired,
  onAddPurchase: PropTypes.func.isRequired,
}

export { PrizesContent }
