import { methods } from '../constants/apiMethods'
import api from './api.helper'

const url = '/categories'

export const getCategories = () =>
  api(methods.get, url).then(({ data: categories }) => categories)

export const addCategory = (data) => api(methods.post, url, data)

export const updateCategory = (id, data) =>
  api(methods.update, `${url}/${id}`, data)

export const deleteCategory = (id) => api(methods.delete, `${url}/${id}`)
