import './participants.scss'

import React, { useContext, useEffect, useRef, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { AppLayout } from '../../components/AppLayout/AppLayout.component'
import { Participant } from './participant/participant.component'
import { getUserProfile, getUsers } from '../../services/user.service'
import { isAdmin } from '../../services/roles.sevice'
import { NewParticipant } from './newParticipant/newParticipant.component'
import { addParticipation } from '../../services/participations.service'
import { UserContext } from '../../context/UserProvider'

const Participants = () => {
  const toast = useRef(null)
  const user = useContext(UserContext)
  const [userData, setUserData] = useState(null)
  const [participants, setParticipants] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const filteredParticipants = participants.filter((x) =>
    x.name.toLowerCase().includes(searchTerm.toLowerCase())
  )
  const [isParticipantModalVisible, setIsParticipantModalVisible] =
    useState(false)
  const [isNewModalVisible, setIsNewModalVisible] = useState(false)
  const [selectedUser, setSelectedUser] = useState(0)
  const [selectedParticipation, setSelectedParticipation] = useState({
    id: null,
    points: '',
    contest_id: '',
    participant_id: '',
  })

  const rowClass = (data) => {
    return {
      'participants__table--current': data.id === userData.id,
    }
  }

  const fetchUsers = async () => {
    const users = await getUsers()
    const currentUser = await getUserProfile()
    const mapUsers = users.map((x) => {
      return {
        name: x.name,
        score: x.total_points,
        id: x.id,
      }
    })
    setParticipants(mapUsers)
    setUserData(currentUser)
  }

  const setParticipantFalse = async () => {
    await fetchUsers()
    setIsParticipantModalVisible(false)
  }

  const setNewFalse = () => {
    setIsNewModalVisible(false)
    setSelectedParticipation({
      id: null,
      points: '',
      contest_id: '',
      participant_id: '',
    })
  }

  const handleParticipationAdd = async () => {
    await addParticipation(selectedParticipation)
      .then(() => {
        toast.current.show({
          severity: 'success',
          summary: 'Added Succesfully',
          detail: 'Participation added Succesfully',
        })
      })
      .catch((err) => {
        toast.current.show({
          severity: 'error',
          summary: 'Added Failed',
          detail: err.message,
        })
      })
    setSelectedParticipation({
      id: null,
      points: '',
      contest_id: selectedParticipation.contest_id,
      participant_id: '',
    })
    fetchUsers()
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  return (
    <AppLayout>
      {isParticipantModalVisible && (
        <Participant
          isModalVisible={isParticipantModalVisible}
          onModalHide={setParticipantFalse}
          Id={selectedUser}
        />
      )}
      {isNewModalVisible && (
        <NewParticipant
          isModalVisible={isNewModalVisible}
          onModalHide={setNewFalse}
          selectedParticipation={selectedParticipation}
          setParticipation={setSelectedParticipation}
          onParticipationAdd={handleParticipationAdd}
        />
      )}
      <Toast ref={toast} />
      <div className="participants">
        <div className="participants__container">
          <div className="participants__controlls">
            <InputText
              className="participants__controlls__search-bar"
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {isAdmin(user) && (
              <Button
                className="participants__controlls__button"
                onClick={() => {
                  setIsNewModalVisible(true)
                }}
              >
                Add Points
              </Button>
            )}
          </div>
          <div className="participants__table">
            <DataTable
              tableClassName="tab"
              metaKeySelection
              selectionMode="single"
              onSelectionChange={(e) => {
                setSelectedUser(e.value.id)
                setIsParticipantModalVisible(true)
              }}
              value={filteredParticipants}
              rowClassName={rowClass}
              breakpoint="450px"
            >
              <Column field="name" sortable header="Name" />
              <Column field="score" sortable header="Score" />
            </DataTable>
          </div>
        </div>
      </div>
    </AppLayout>
  )
}

export { Participants }
