const LOCAL_STORAGE_KEYS = {
  USER_DATA: 'DEV_WEEK_APP_USER_DATA',
  ACCESS_TOKEN: 'DEV_WEEK_APP_ACCESS_TOKEN',
}

export const setAuthToken = (authToken) => {
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
    JSON.stringify(authToken)
  )
}
export const getAuthToken = () => {
  try {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN))
  } catch (err) {
    return null
  }
}
