import './categories.scss'

import React, { useEffect, useState, useRef } from 'react'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { AppLayout } from '../../components/AppLayout'
import { CategoriesDialog } from './categoriesDialog'
import {
  addCategory,
  deleteCategory,
  getCategories,
  updateCategory,
} from '../../services/categories.service'

const Categories = () => {
  const toast = useRef(null)
  const [categories, setCategories] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const filteredCategoires = categories.filter((x) =>
    x.name.toLowerCase().includes(searchTerm.toLowerCase())
  )
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState({
    name: '',
    description: '',
    id: undefined,
  })
  const hideModal = () => {
    setIsModalVisible(false)
    setSelectedCategory({
      name: '',
      description: '',
      id: undefined,
    })
  }

  const fetchCategoires = async () => {
    const servCategories = await getCategories()
    setCategories(servCategories)
  }

  const handleCategoryAdd = async () => {
    if (selectedCategory.id) {
      await updateCategory(selectedCategory.id, {
        name: selectedCategory.name,
        description: selectedCategory.description,
      })
        .then(() => {
          toast.current.show({
            severity: 'success',
            summary: 'Update SuccesfullY',
            detail: 'Category updated Succesfully',
          })
        })
        .catch((err) => {
          toast.current.show({
            severity: 'error',
            summary: 'Delete Failed',
            detail: err.message,
          })
        })
    } else {
      await addCategory({
        name: selectedCategory.name,
        description: selectedCategory.description,
      })
        .then(() => {
          toast.current.show({
            severity: 'success',
            summary: 'Added Succesfully',
            detail: 'Category added Succesfully',
          })
        })
        .catch((err) => {
          toast.current.show({
            severity: 'error',
            summary: 'Added Failed',
            detail: err.message,
          })
        })
    }
    fetchCategoires()
    hideModal()
  }
  const handleCategoryDelete = async () => {
    await deleteCategory(selectedCategory.id)
      .then(() => {
        toast.current.show({
          severity: 'success',
          summary: 'Deleted Succesfully',
          detail: 'Category deleted Succesfully',
        })
      })
      .catch((err) => {
        toast.current.show({
          severity: 'error',
          summary: 'Delete Failed',
          detail: err.message,
        })
      })

    fetchCategoires()
    hideModal()
  }
  const handleDeleteConfirm = () => {
    confirmDialog({
      icon: 'pi pi-info-circle',
      message: 'Do you want to delete this?',
      header: 'Delete Confirmation',
      acceptClassName: 'p-button-danger',
      accept: () => handleCategoryDelete(selectedCategory.id),
    })
  }

  useEffect(() => {
    fetchCategoires()
  }, [])

  return (
    <AppLayout>
      <ConfirmDialog />
      <Toast ref={toast} />
      <CategoriesDialog
        onModalHide={hideModal}
        onCategoryAdd={handleCategoryAdd}
        onCategoryDelete={handleDeleteConfirm}
        isModalVisible={isModalVisible}
        selectedCategory={selectedCategory}
        setCategory={setSelectedCategory}
        isEdit={selectedCategory.id}
      />
      <div className="categories">
        <div className="categories__container">
          <div className="categories__add-button">
            <InputText
              className="categories__search"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button onClick={() => setIsModalVisible(true)}>
              Add Category
            </Button>
          </div>
          <div className="categories__table">
            <DataTable
              metaKeySelection
              selectionMode="single"
              onSelectionChange={(e) => {
                setSelectedCategory(e.value)
                setIsModalVisible(true)
              }}
              value={filteredCategoires}
            >
              <Column field="name" header="Category Name" sortable />
              <Column field="description" header="Description" />
              <Column field="questions" header="Questions" />
            </DataTable>
          </div>
        </div>
      </div>
    </AppLayout>
  )
}

export { Categories }
