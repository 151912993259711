import './categoiresDialog.scss'

import React from 'react'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import PropTypes from 'prop-types'
import { InputTextarea } from 'primereact/inputtextarea'

const CategoriesDialog = ({
  isEdit,
  isModalVisible,
  onModalHide = () => {},
  onCategoryAdd = () => {},
  onCategoryDelete = () => {},
  selectedCategory,
  setCategory = () => {},
}) => (
  <Dialog
    header="Add Category"
    visible={isModalVisible}
    onHide={onModalHide}
    position="top"
    contentClassName="category-dialog"
    draggable={false}
  >
    <span className="p-float-label">
      <InputText
        id="name"
        value={selectedCategory.name}
        onChange={(e) =>
          setCategory({
            name: e.target.value,
            description: selectedCategory.description,
            id: selectedCategory.id,
          })
        }
        className="category-dialog__input"
      />
      <label htmlFor="name">Category Name</label>
    </span>
    <span className="p-float-label">
      <InputTextarea
        id="description"
        rows={1}
        value={selectedCategory.description}
        onChange={(e) =>
          setCategory({
            name: selectedCategory.name,
            description: e.target.value,
            id: selectedCategory.id,
          })
        }
        autoResize
        className="category-dialog__input"
      />
      <label htmlFor="description">Description</label>
    </span>
    <div
      className={
        isEdit
          ? 'category-dialog__buttons category-dialog__buttons--delete'
          : 'category-dialog__buttons'
      }
    >
      <Button
        className="p-button-sm"
        disabled={
          !selectedCategory.name + !selectedCategory.description && true
        }
        onClick={onCategoryAdd}
      >
        {isEdit ? 'Edit Category' : 'Save Category'}
      </Button>
      {isEdit && (
        <Button className="p-button-sm" onClick={onCategoryDelete}>
          Delete Category
        </Button>
      )}
    </div>
    <small className="p-success block help-text">
      All fields must be filled!
    </small>
  </Dialog>
)

CategoriesDialog.propTypes = {
  isEdit: PropTypes.number,
  isModalVisible: PropTypes.bool.isRequired,
  onModalHide: PropTypes.func.isRequired,
  onCategoryAdd: PropTypes.func.isRequired,
  onCategoryDelete: PropTypes.func.isRequired,
  selectedCategory: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  setCategory: PropTypes.func.isRequired,
}

CategoriesDialog.defaultProps = {
  isEdit: null,
}
export { CategoriesDialog }
