import { methods } from '../constants/apiMethods'
import api from './api.helper'

const url = '/questions'

export const getQuestions = () =>
  api(methods.get, url).then(({ data: contest }) => contest)

export const getQuestion = (id) =>
  api(methods.get, `${url}/${id}`).then(({ data: contest }) => contest)

export const updateQuestion = (id, data) =>
  api(methods.update, `${url}/${id}`, data)

export const addQuestion = (data) => api(methods.post, url, data)
export const deleteQuestion = (id) => api(methods.delete, `${url}/${id}`)
