import './difficultiesDialog.scss'

import React from 'react'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import PropTypes from 'prop-types'

const DifficultyDialog = ({
  isEdit,
  isModalVisible,
  onModalHide = () => {},
  onDifficultyAdd = () => {},
  onDifficulyDelete = () => {},
  selectedDifficulty,
  setDifficulty = () => {},
}) => (
  <Dialog
    header="Add Difficulty"
    visible={isModalVisible}
    onHide={onModalHide}
    position="top"
    contentClassName="difficulty-dialog"
    draggable={false}
  >
    <span className="p-float-label">
      <InputText
        id="name"
        value={selectedDifficulty.name}
        onChange={(e) =>
          setDifficulty({
            name: e.target.value,
            points: selectedDifficulty.points,
            id: selectedDifficulty.id,
          })
        }
        className="difficulty-dialog__input"
      />
      <label htmlFor="name">Difficulty Name</label>
    </span>
    <span className="p-float-label">
      <InputText
        id="points"
        value={selectedDifficulty.points}
        keyfilter="num"
        onChange={(e) =>
          setDifficulty({
            name: selectedDifficulty.name,
            points: e.target.value,
            id: selectedDifficulty.id,
          })
        }
        className="difficulty-dialog__input"
      />
      <label htmlFor="points">Difficulty Points</label>
    </span>
    <div
      className={
        isEdit
          ? 'difficulty-dialog__buttons difficulty-dialog__buttons--delete'
          : 'difficulty-dialog__buttons'
      }
    >
      <Button
        className="p-button-sm"
        onClick={onDifficultyAdd}
        disabled={!selectedDifficulty.name + !selectedDifficulty.points && true}
      >
        {isEdit ? 'Edit Difficulty' : 'Save Difficulty'}
      </Button>
      {isEdit && (
        <Button className="p-button-sm" onClick={onDifficulyDelete}>
          Delete difficulty
        </Button>
      )}
    </div>
    <small className="p-success block help-text">
      All fields must be filled!
    </small>
  </Dialog>
)

DifficultyDialog.propTypes = {
  isEdit: PropTypes.number,
  isModalVisible: PropTypes.bool.isRequired,
  onModalHide: PropTypes.func.isRequired,
  onDifficultyAdd: PropTypes.func.isRequired,
  onDifficulyDelete: PropTypes.func.isRequired,
  selectedDifficulty: PropTypes.shape({
    name: PropTypes.string,
    points: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    id: PropTypes.number,
  }).isRequired,
  setDifficulty: PropTypes.func.isRequired,
}

DifficultyDialog.defaultProps = {
  isEdit: null,
}

export { DifficultyDialog }
