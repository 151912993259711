import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react'
import React from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
import { routes } from '../../constants/routes'

const RequireAuth = ({ children }) => (
  <>
    <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <Navigate to={routes.auth} replace />
    </UnauthenticatedTemplate>
  </>
)

RequireAuth.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}
export { RequireAuth }
