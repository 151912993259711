import './UserRedeems.scss'
import React, { useState, useEffect } from 'react'

import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import { getUserPurchases } from '../../../services/purchases.service'
import { getUserProfile } from '../../../services/user.service'

const UserRedeems = () => {
  const [purchases, setPurchases] = useState([])
  const fetchPurchases = async () => {
    const servUser = await getUserProfile()
    const servPurchases = await getUserPurchases(servUser.id)
    setPurchases(servPurchases)
  }
  useEffect(() => {
    fetchPurchases()
  }, [])

  return (
    <div className="user-redeems">
      <div className="user-redeems__container">
        <div className="user-redeems__table">
          <DataTable
            metaKeySelection
            selectionMode="single"
            value={purchases}
            breakpoint="360px"
          >
            <Column field="item.title" header="Prize redeemed" />
            <Column field="points" header="Points" />
            <Column field="quantity" header="Quantity" />
          </DataTable>
        </div>
      </div>
    </div>
  )
}

export default UserRedeems
