import React from 'react'

import { Button } from 'primereact/button'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from '../../constants/authConfig'

const handleLogin = (instance) => {
  instance.loginRedirect(loginRequest).then(() => {
    window.location.reload(true)
  })
}

const LogInButton = () => {
  const { instance } = useMsal()

  return (
    <Button onClick={() => handleLogin(instance)}>Login with Microsoft</Button>
  )
}

export { LogInButton }
