import { methods } from '../constants/apiMethods'
import api from './api.helper'

const url = '/participations'

export const addParticipation = (data) => api(methods.post, url, data)

export const updateParticipation = (id, data) =>
  api(methods.update, `${url}/${id}`, data)

export const deleteParticipation = (id) => api(methods.delete, `${url}/${id}`)
