import './difficulties.scss'

import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toast } from 'primereact/toast'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { AppLayout } from '../../components/AppLayout/AppLayout.component'
import { DifficultyDialog } from './difficultyDialog/difficultyDialog.component'
import {
  addDifficulty,
  deleteDifficulty,
  getDifficulties,
  updateDifficulty,
} from '../../services/difficulties.service'

const Difficulties = () => {
  const toast = useRef(null)
  const [difficulties, setDifficulties] = useState([])
  const [modalToggle, setModalToggle] = useState(false)
  const [selectedDifficulty, setSelectedDifficulty] = useState({
    name: '',
    points: '',
    id: undefined,
  })
  // const [data, setData] = useState('')
  const hideModal = () => {
    setModalToggle(false)
    setSelectedDifficulty({
      name: '',
      points: '',
      id: undefined,
    })
  }
  const fetchDificulties = async () => {
    const servDifficulties = await getDifficulties()
    setDifficulties(servDifficulties)
  }

  const handleDifficultyAdd = async () => {
    if (selectedDifficulty.id) {
      await updateDifficulty(selectedDifficulty.id, {
        name: selectedDifficulty.name,
        points: selectedDifficulty.points,
      })
        .then(() => {
          toast.current.show({
            severity: 'success',
            summary: 'Update Succesfully',
            detail: 'Difficulty updated Succesfully',
          })
        })
        .catch((err) => {
          toast.current.show({
            severity: 'error',
            summary: 'Update Failed',
            detail: err.message,
          })
        })
    } else {
      await addDifficulty({
        name: selectedDifficulty.name,
        points: selectedDifficulty.points,
      })
        .then(() => {
          toast.current.show({
            severity: 'success',
            summary: 'Added Succesfully',
            detail: 'Difficulty added Succesfully',
          })
        })
        .catch((err) => {
          toast.current.show({
            severity: 'error',
            summary: 'Added Failed',
            detail: err.message,
          })
        })
    }
    fetchDificulties()
    hideModal()
  }
  const handleDifficulyDelete = async () => {
    await deleteDifficulty(selectedDifficulty.id)
      .then(() => {
        toast.current.show({
          severity: 'success',
          summary: 'Deleted Succesfully',
          detail: 'Difficulty deleted Succesfully',
        })
      })
      .catch((err) => {
        toast.current.show({
          severity: 'error',
          summary: 'Delete Failed',
          detail: err,
        })
      })

    fetchDificulties()
    hideModal()
  }
  const handleDeleteConfirm = () => {
    confirmDialog({
      message: 'Do you want to delete this?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => handleDifficulyDelete(selectedDifficulty.id),
    })
  }

  useEffect(() => {
    fetchDificulties()
  }, [])

  return (
    <AppLayout>
      <ConfirmDialog />
      <Toast ref={toast} />
      <DifficultyDialog
        onModalHide={hideModal}
        onDifficultyAdd={handleDifficultyAdd}
        onDifficulyDelete={handleDeleteConfirm}
        isModalVisible={modalToggle}
        selectedDifficulty={selectedDifficulty}
        setDifficulty={setSelectedDifficulty}
        isEdit={selectedDifficulty.id}
      />
      <div className="difficulties">
        <div className="difficulties__container">
          <div className="difficulties__add-button">
            <Button onClick={() => setModalToggle(true)}>Add Dificulty</Button>
          </div>
          <div className="difficulties__table">
            <DataTable
              metaKeySelection
              selectionMode="single"
              onSelectionChange={(e) => {
                setSelectedDifficulty(e.value)
                setModalToggle(true)
              }}
              value={difficulties}
              breakpoint="360px"
            >
              <Column field="name" header="Difficulty Name" />
              <Column field="points" header="Points" />
            </DataTable>
          </div>
        </div>
      </div>
    </AppLayout>
  )
}

export { Difficulties }
