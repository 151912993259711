import React, { useState } from 'react'
import './EventsContent.scss'

import { Button } from 'primereact/button'

import moment from 'moment'
import PropTypes from 'prop-types'
import { EventContentDialog } from '../EventsContentDialog/EventContentDialog.component'

const EventsContent = ({ event, fetchEvents = () => {} }) => {
  const [openDialog, setOpenDialog] = useState(false)
  const hideDialog = async () => {
    fetchEvents()
    setOpenDialog(false)
  }

  return (
    <div className="event-content-form">
      {openDialog && (
        <EventContentDialog
          isModalVisible={openDialog}
          onModalHide={hideDialog}
          id={event.id}
        />
      )}
      <div className="event-content-form__info-panel-between">
        <h2> Hour </h2>
        <span>{moment(event.date).format('HH:mm').toString()}</span>
      </div>
      <div className="event-content-form__info">
        <div className="event-content-form__info-panel-center">
          <h1> {event.title} </h1>
          <p className="event-content-form__description-content">
            {event.description}
          </p>
          <div>
            <Button
              className="event-content-form__modal-button"
              label="Event Details"
              icon="pi pi-external-link"
              onClick={() => setOpenDialog(true)}
            />
          </div>
        </div>
      </div>
      <div className="event-content-form__info-panel-between">
        <h2> Points </h2>
        <span className="event-content-form__span-points">
          {event.max_points}
        </span>
      </div>
    </div>
  )
}

EventsContent.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    date: PropTypes.instanceOf(Date),
    max_points: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  fetchEvents: PropTypes.func.isRequired,
}
export { EventsContent }
