import { methods } from '../constants/apiMethods'
import api from './api.helper'

const url = 'items'

export const getItems = () =>
  api(methods.get, url).then(({ data: items }) => items.items)

export const addItem = (data) => api(methods.post, url, data)

export const deleteItem = (id) => api(methods.delete, `${url}/${id}`)

export const updateItem = (id, data) =>
  api(methods.update, `${url}/${id}`, data)

export const getBudget = () =>
  api(methods.get, url).then(({ data: items }) => items.remaining_budget)
