import './events.scss'

import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { TabView, TabPanel } from 'primereact/tabview'

import { AppLayout } from '../../components/AppLayout/AppLayout.component'
import { EventsContent } from './EventsContent/EventsContent.component'

import { getContests } from '../../services/events.service'

const Events = () => {
  const [events, setEvents] = useState([])
  const currentDay = new Date().getDay() - 1 > 4 ? 0 : new Date().getDay() - 1

  const fetchEvents = async () => {
    const servEvents = await getContests()
    const mapEvents = servEvents.map((x) => {
      return {
        id: x.id,
        title: x.title,
        description: x.description,
        max_points: x.max_points,
        date: new Date(moment.parseZone(x.date).format('DD MMMM YYYY, HH:mm')),
      }
    })
    setEvents(mapEvents)
  }

  useEffect(() => {
    fetchEvents()
  }, [])

  return (
    <AppLayout>
      <div className="event-form">
        <div className="event-form__content">
          <TabView activeIndex={currentDay}>
            <TabPanel header="Monday">
              {events
                .filter((e) => e.date.getDay() === 1)
                .sort((a, b) => a.date - b.date)
                .map((item) => (
                  <EventsContent
                    key={item.id}
                    event={{
                      title: item.title,
                      id: item.id,
                      date: item.date,
                      max_points: item.max_points,
                      description: item.description,
                    }}
                    fetchEvents={fetchEvents}
                  />
                ))}
            </TabPanel>
            <TabPanel header="Tuesday">
              {events
                .filter((e) => e.date.getDay() === 2)
                .sort((a, b) => a.date - b.date)
                .map((item) => (
                  <EventsContent
                    key={item.id}
                    event={{
                      title: item.title,
                      id: item.id,
                      date: item.date,
                      max_points: item.max_points,
                      description: item.description,
                    }}
                    fetchEvents={fetchEvents}
                  />
                ))}
            </TabPanel>
            <TabPanel header="Wednesday">
              {events
                .filter((e) => e.date.getDay() === 3)
                .sort((a, b) => a.date - b.date)
                .map((item) => (
                  <EventsContent
                    key={item.id}
                    event={{
                      title: item.title,
                      id: item.id,
                      date: item.date,
                      max_points: item.max_points,
                      description: item.description,
                    }}
                    fetchEvents={fetchEvents}
                  />
                ))}
            </TabPanel>
            <TabPanel header="Thursday">
              {events
                .filter((e) => e.date.getDay() === 4)
                .map((item) => (
                  <EventsContent
                    key={item.id}
                    event={{
                      title: item.title,
                      id: item.id,
                      date: item.date,
                      max_points: item.max_points,
                      description: item.description,
                    }}
                    fetchEvents={fetchEvents}
                  />
                ))}
            </TabPanel>
            <TabPanel header="Friday">
              {events
                .filter((e) => e.date.getDay() === 5)
                .sort((a, b) => a.date - b.date)
                .map((item) => (
                  <EventsContent
                    key={item.id}
                    event={{
                      title: item.title,
                      id: item.id,
                      date: item.date,
                      max_points: item.max_points,
                      description: item.description,
                    }}
                    fetchEvents={fetchEvents}
                  />
                ))}
            </TabPanel>
          </TabView>
        </div>
      </div>
    </AppLayout>
  )
}

export { Events }
