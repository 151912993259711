import { methods } from '../constants/apiMethods'
import api from './api.helper'

const url = '/contests'

export const getContests = () =>
  api(methods.get, url).then(({ data: contest }) => contest)

export const getContest = (id) =>
  api(methods.get, `${url}/${id}`).then(({ data: contest }) => contest)

export const updateContest = (id, data) =>
  api(methods.update, `${url}/${id}`, data)
