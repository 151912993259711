import './PrizeDialog.scss'
import React from 'react'

import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { InputNumber } from 'primereact/inputnumber'
import { Button } from 'primereact/button'
import { FileUpload } from 'primereact/fileupload'
import PropTypes from 'prop-types'

const PrizeDialog = ({
  selectedPrize,
  isPrizeDialogVisible,
  onEditButton = () => {},
  onPrizeDialogHide = () => {},
  setPrize = () => {},
}) => (
  <div>
    <Dialog
      header="Add/Edit Prize Details"
      visible={isPrizeDialogVisible}
      onHide={onPrizeDialogHide}
      draggable={false}
      contentClassName="dialog-edit-prize"
    >
      <div className="p-float-label">
        <InputText
          id="title"
          required
          className=" dialog-edit-prize__input"
          value={selectedPrize.title}
          onChange={(e) =>
            setPrize({
              title: e.target.value,
              description: selectedPrize.description,
              points: selectedPrize.points,
              id: selectedPrize.id,
              image: selectedPrize.image,
            })
          }
        />
        <label htmlFor="title">Prize Title</label>
      </div>
      <div className="p-float-label">
        <InputNumber
          inputId="price"
          className="dialog-edit-prize__input"
          useGrouping={false}
          min={0}
          max={100000}
          format={false}
          value={selectedPrize.points}
          onValueChange={(e) =>
            setPrize({
              title: selectedPrize.title,
              description: selectedPrize.description,
              points: e.target.value,
              id: selectedPrize.id,
              image: selectedPrize.image,
            })
          }
        />
        <label htmlFor="price">Price</label>
      </div>
      <div className="p-float-label">
        <InputTextarea
          value={selectedPrize.description}
          rows={5}
          cols={30}
          onChange={(e) =>
            setPrize({
              title: selectedPrize.title,
              description: e.target.value,
              points: selectedPrize.points,
              id: selectedPrize.id,
              image: selectedPrize.image,
            })
          }
        />
        <label htmlFor="description">Description</label>
      </div>
      <div className="dialog-edit-prize__file">
        <FileUpload
          chooseLabel="Choose a photo"
          className="w-full mt-3"
          mode="basic"
          accept="image/*"
          maxFileSize={5000000}
          onSelect={(e) => {
            setPrize({
              title: selectedPrize.title,
              description: selectedPrize.description,
              points: selectedPrize.points,
              id: selectedPrize.id,
              image: e.files[0],
            })
          }}
        />
      </div>

      <div className="dialog-edit-prize__button">
        <Button
          className="p-button-sm"
          onClick={onEditButton}
          disabled={
            !selectedPrize.title +
              !selectedPrize.points +
              !selectedPrize.description && true
          }
        >
          Save
        </Button>
      </div>
      <small className="p-success block help-text">
        All fields must be filled!
      </small>
    </Dialog>
  </div>
)

PrizeDialog.propTypes = {
  onEditButton: PropTypes.func.isRequired,
  setPrize: PropTypes.func.isRequired,
  isPrizeDialogVisible: PropTypes.bool.isRequired,
  onPrizeDialogHide: PropTypes.func.isRequired,
  selectedPrize: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    points: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    description: PropTypes.string,
    image: PropTypes.instanceOf(File),
  }).isRequired,
}

export default PrizeDialog
