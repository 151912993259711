import './AppLayout.scss'

import React from 'react'
import PropTypes from 'prop-types'
import { AuthenticatedTemplate } from '@azure/msal-react'
import { ParticlesJS } from './Particles.component'
import { Navbar } from './NavBar/Navbar.component'

const AppLayout = ({ children }) => (
  <div className="page">
    <div className="page__container">
      <AuthenticatedTemplate>
        <Navbar />
      </AuthenticatedTemplate>
      <div className="page__container__children">{children}</div>
    </div>
    <ParticlesJS />
  </div>
)

AppLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export { AppLayout }
