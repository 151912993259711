import './questionModal.scss'
import React, { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import PropTypes from 'prop-types'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'
import { Divider } from 'primereact/divider'
import { getContests } from '../../../services/events.service'
import { getCategories } from '../../../services/categories.service'
import { getDifficulties } from '../../../services/difficulties.service'
import { Answer } from '../answer/answer.component'

const QuestionModal = ({
  isModalVisible,
  onModalHide = () => {},
  onQuestionAdd = () => {},
  onQuestionDelete = () => {},
  selectedQuestion,
  setSelectedQuestion = () => {},
}) => {
  const availbaleAnswers = selectedQuestion.answers_attributes.filter(
    (x) => x._destroy !== true
  )
  const [categories, setCategories] = useState(null)
  const [difficulties, setDifficulties] = useState(null)
  const [contests, setContests] = useState(null)

  const fetchData = async () => {
    const fetchedDifficulties = await getDifficulties()
    setDifficulties(fetchedDifficulties)
    const fetchedContests = await getContests()
    setContests(fetchedContests)
    const fetchedCategoires = await getCategories()
    setCategories(fetchedCategoires)
  }

  const handleAddAnswer = () => {
    setSelectedQuestion({
      id: selectedQuestion.id,
      description: selectedQuestion.description,
      difficulty_id: selectedQuestion.difficulty_id,
      contest_id: selectedQuestion.contest_id,
      category_id: selectedQuestion.category_id,
      answers_attributes: [
        ...selectedQuestion.answers_attributes,
        { description: '', correct: false },
      ],
    })
  }
  const handleDeleteAnswer = (deletedObject, id) => {
    if (deletedObject.id) {
      setSelectedQuestion({
        id: selectedQuestion.id,
        description: selectedQuestion.description,
        difficulty_id: selectedQuestion.difficulty_id,
        contest_id: selectedQuestion.contest_id,
        category_id: selectedQuestion.category_id,
        answers_attributes: selectedQuestion.answers_attributes.map((x) => {
          if (x.id !== deletedObject.id) {
            return x
          }

          return { ...x, _destroy: true }
        }),
      })
    } else {
      setSelectedQuestion({
        id: selectedQuestion.id,
        description: selectedQuestion.description,
        difficulty_id: selectedQuestion.difficulty_id,
        contest_id: selectedQuestion.contest_id,
        category_id: selectedQuestion.category_id,
        answers_attributes: selectedQuestion.answers_attributes.filter(
          (x, index) => index !== id
        ),
      })
    }
  }

  const handleChangeAnswer = (id, newObj) => {
    setSelectedQuestion({
      id: selectedQuestion.id,
      description: selectedQuestion.description,
      difficulty_id: selectedQuestion.difficulty_id,
      contest_id: selectedQuestion.contest_id,
      category_id: selectedQuestion.category_id,
      answers_attributes: selectedQuestion.answers_attributes.map(
        (x, index) => {
          if (index !== id) return x

          return newObj
        }
      ),
    })
  }

  const footer = (
    <div
      className={
        selectedQuestion.id
          ? 'question__button question__button--delete'
          : 'question__button'
      }
    >
      <Button onClick={() => onQuestionAdd()}>
        {selectedQuestion.id ? 'Update Question' : 'Add Question'}
      </Button>
      {selectedQuestion.id && (
        <Button onClick={() => onQuestionDelete()}>Delete</Button>
      )}
    </div>
  )

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Dialog
      position="top"
      visible={isModalVisible}
      onHide={() => onModalHide()}
      draggable={false}
      className="dialog"
      contentClassName="dialog__content"
      footer={footer}
    >
      <div className="question">
        <InputTextarea
          autoResize
          className="question__input"
          placeholder="Enter Description"
          value={selectedQuestion.description}
          onChange={(e) =>
            setSelectedQuestion({
              id: selectedQuestion.id,
              description: e.target.value,
              difficulty_id: selectedQuestion.difficulty_id,
              contest_id: selectedQuestion.contest_id,
              category_id: selectedQuestion.category_id,
              answers_attributes: selectedQuestion.answers_attributes,
            })
          }
        />
        <Dropdown
          className="question__dropdown"
          optionLabel="name"
          optionValue="id"
          value={selectedQuestion.category_id}
          options={categories}
          filter
          onChange={(e) =>
            setSelectedQuestion({
              id: selectedQuestion.id,
              description: selectedQuestion.description,
              difficulty_id: selectedQuestion.difficulty_id,
              contest_id: selectedQuestion.contest_id,
              category_id: e.value,
              answers_attributes: selectedQuestion.answers_attributes,
            })
          }
          placeholder="Select a Category"
        />
        <Dropdown
          className="question__dropdown"
          optionLabel="name"
          optionValue="id"
          value={selectedQuestion.difficulty_id}
          options={difficulties}
          filter
          onChange={(e) =>
            setSelectedQuestion({
              id: selectedQuestion.id,
              description: selectedQuestion.description,
              difficulty_id: e.value,
              contest_id: selectedQuestion.contest_id,
              category_id: selectedQuestion.category_id,
              answers_attributes: selectedQuestion.answers_attributes,
            })
          }
          placeholder="Select a Difficulty"
        />
        <Dropdown
          className="question__dropdown"
          optionLabel="title"
          optionValue="id"
          filter
          value={selectedQuestion.contest_id}
          options={contests}
          onChange={(e) =>
            setSelectedQuestion({
              id: selectedQuestion.id,
              description: selectedQuestion.description,
              difficulty_id: selectedQuestion.difficulty_id,
              contest_id: e.value,
              category_id: selectedQuestion.category_id,
              answers_attributes: selectedQuestion.answers_attributes,
            })
          }
          placeholder="Select a Contest"
        />
        <div className="question__answers">
          <h2>Answers</h2>
          <Button
            icon="pi pi-plus"
            className="p-button-rounded p-button-text"
            onClick={() => {
              handleAddAnswer()
            }}
          />
        </div>
        <Divider />
        {availbaleAnswers.map((x, idx) => (
          <Answer
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            onDeleteAnswer={() => {
              handleDeleteAnswer(x, idx)
            }}
            onChangeAnswer={(ev) => {
              handleChangeAnswer(idx, ev)
            }}
            curentAnswer={x}
          />
        ))}
      </div>
    </Dialog>
  )
}

QuestionModal.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  onModalHide: PropTypes.func.isRequired,
  onQuestionAdd: PropTypes.func.isRequired,
  onQuestionDelete: PropTypes.func.isRequired,
  selectedQuestion: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    difficulty_id: PropTypes.number,
    contest_id: PropTypes.number,
    category_id: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    answers_attributes: PropTypes.any,
  }).isRequired,
  setSelectedQuestion: PropTypes.func.isRequired,
}

export { QuestionModal }
