import React from 'react'

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react'
import { Navigate } from 'react-router-dom'
import { LogInButton } from './LogInButton.component'
import { AppLayout } from '../../components/AppLayout'
import { routes } from '../../constants/routes'

const Auth = () => (
  <>
    <AuthenticatedTemplate>
      <Navigate to={routes.dashboard} replace />
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <AppLayout>
        <LogInButton />
      </AppLayout>
    </UnauthenticatedTemplate>
  </>
)

export { Auth }
