import React from 'react'
import '../../components/AppLayout/NavBar/Navbar.scss'

import { Button } from 'primereact/button'

const LogOutButton = () => {
  const handleLogout = () => {
    localStorage.clear()
    window.location.reload(true)
  }

  return (
    <Button onClick={handleLogout} className="p-button-text">
      Log out
    </Button>
  )
}

export { LogOutButton }
