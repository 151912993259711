import './prizes.scss'

import React, { useState, useEffect, useRef, useContext } from 'react'

import { TabView, TabPanel } from 'primereact/tabview'
import { Toast } from 'primereact/toast'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'

import { PrizesContent } from './PrizesContent'
import { AppLayout } from '../../components/AppLayout/AppLayout.component'
import UserRedeems from './UserRedeems/UserRedeems.component'
import UsersRedeemsAdmin from './UsersRedeemsAdmin/UsersRedeemsAdmin.component'
import PrizeDialog from './PrizeDialog/PrizeDialog.component'

import { isAdmin, isUser } from '../../services/roles.sevice'
import {
  getItems,
  deleteItem,
  updateItem,
  addItem,
  getBudget,
} from '../../services/prizes.service'
import { addPurchases } from '../../services/purchases.service'
import { getUserProfile } from '../../services/user.service'
import { UserContext } from '../../context/UserProvider'

const Prizes = () => {
  const user = useContext(UserContext)
  const toast = useRef(null)
  const [prizes, setPrizes] = useState([])
  const [currentUser, setCurrentUser] = useState([])
  // currentBudget will be available after Wednesday`s meeting
  const [currentBudget, setCurrentBudget] = useState([])
  const [openPrizeDialog, setOpenPrizeDialog] = useState(false)
  const [selectedPrize, setSelectedPrize] = useState({
    title: '',
    description: '',
    points: '',
    id: undefined,
    image: null,
  })

  const fetchAditionalData = async () => {
    const servUser = await getUserProfile()
    setCurrentUser(servUser)
    const servBudget = await getBudget()
    setCurrentBudget(servBudget)
  }
  const hideDialog = () => {
    setOpenPrizeDialog(false)
    setSelectedPrize({
      title: '',
      description: '',
      points: '',
      id: undefined,
      image: null,
    })
  }

  const handleAddPurchase = async (prize) => {
    fetchAditionalData()
    await addPurchases(prize)
      .then(() => {
        toast.current.show({
          severity: 'success',
          summary: 'Redeem Prize Succesfully',
          detail: 'Prize redeemed Succesfully',
        })
      })
      .catch((err) => {
        toast.current.show({
          severity: 'error',
          summary: 'Redeem Failed',
          detail: err.message,
        })
      })
  }

  const fetchPrizes = async () => {
    const servPrizes = await getItems()
    setPrizes(servPrizes)
  }

  const handleEditDialog = (prize) => {
    setSelectedPrize(prize)
    setOpenPrizeDialog(true)
  }

  const onEditPrize = async () => {
    const prizeForm = new FormData()
    prizeForm.append('item[title]', selectedPrize.title)
    prizeForm.append('item[description]', selectedPrize.description)
    prizeForm.append('item[points]', selectedPrize.points)
    prizeForm.append('item[file]', selectedPrize.image)
    if (selectedPrize.id) {
      await updateItem(selectedPrize.id, prizeForm)
        .then(() => {
          toast.current.show({
            severity: 'success',
            summary: 'Edit Prize Succesfully',
            detail: 'Prize edited Succesfully',
          })
        })
        .catch((err) => {
          toast.current.show({
            severity: 'error',
            summary: 'Edit Failed',
            detail: err.message,
          })
        })
    } else {
      await addItem(prizeForm)
        .then(() => {
          toast.current.show({
            severity: 'success',
            summary: 'Add Prize Succesfully',
            detail: 'Prize added Succesfully',
          })
        })
        .catch((err) => {
          toast.current.show({
            severity: 'error',
            summary: 'Added Failed',
            detail: err.message,
          })
        })
    }
    await fetchPrizes()
    hideDialog()
  }

  const handleDeletePrize = async (id) => {
    await deleteItem(id)
      .then(() => {
        toast.current.show({
          severity: 'success',
          summary: 'Delete Prize Succesfully',
          detail: 'Prize deleted Succesfully',
        })
      })
      .catch((err) => {
        toast.current.show({
          severity: 'error',
          summary: 'Delete Failed',
          detail: err.message,
        })
      })
    fetchPrizes()
  }
  const handleConfirmRedeem = (prize) => {
    confirmDialog({
      message: `Do you want to redeem ${prize.quantity} ${prize.item_title} for ${prize.item_points} points?`,
      header: 'Redeem  Confirmation',
      className: 'confirm-dialog',
      accept: () => handleAddPurchase(prize),
    })
  }
  const handleDeleteConfirm = (id) => {
    confirmDialog({
      message: 'Do you want to delete this?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => handleDeletePrize(id),
    })
  }

  useEffect(() => {
    fetchAditionalData()
    fetchPrizes()
  }, [])

  return (
    <AppLayout>
      <ConfirmDialog />
      <Toast ref={toast} />
      <div className="prizes-form">
        <div className="prizes-form__content">
          <PrizeDialog
            isPrizeDialogVisible={openPrizeDialog}
            onPrizeDialogHide={hideDialog}
            onEditButton={onEditPrize}
            setPrize={setSelectedPrize}
            selectedPrize={selectedPrize}
          />
          <TabView>
            <TabPanel header="Shop" className="prizes-form__content-dashboard">
              <div className="prizes-form__content-points">
                {isUser(user) && (
                  <div className="prizes-form__content-points-div">
                    <span className="prizes-form__content-points-style">
                      My budget: {currentUser.points_left}
                    </span>
                    <span className="prizes-form__content-points-style">
                      Budget: {currentBudget}
                    </span>
                  </div>
                )}
                {isAdmin(user) && (
                  <button
                    className="prizes-form__button-add-item"
                    type="button"
                    onClick={() => setOpenPrizeDialog(true)}
                  >
                    Add prize
                  </button>
                )}
              </div>
              <div className="prizes-form__content-form">
                {currentBudget > 0 ? (
                  prizes.map((item) => (
                    <PrizesContent
                      key={item.id}
                      prize={{
                        title: item.title,
                        description: item.description,
                        points: item.points,
                        id: item.id,
                        image: item.image,
                      }}
                      onAddPurchase={handleConfirmRedeem}
                      handleDeletePrize={handleDeleteConfirm}
                      onEditPrize={() =>
                        handleEditDialog({
                          title: item.title,
                          description: item.description,
                          points: item.points,
                          id: item.id,
                        })
                      }
                      currentUserPoints={currentUser.points_left}
                    />
                  ))
                ) : (
                  <div className="prizes-form__content-form-unavailable-prizes">
                    Prizes not available
                  </div>
                )}
              </div>
            </TabPanel>
            {isUser(user) && (
              <TabPanel
                header="My redeems"
                className="prize-form__header-title"
              >
                <UserRedeems />
              </TabPanel>
            )}
            {isAdmin(user) && (
              <TabPanel
                header="Users redeems"
                className="prize-form__header-title"
              >
                <UsersRedeemsAdmin />
              </TabPanel>
            )}
          </TabView>
        </div>
      </div>
    </AppLayout>
  )
}

export { Prizes }
