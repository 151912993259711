import { methods } from '../constants/apiMethods'
import api from './api.helper'

const url = '/users'
const urlProfile = '/profile'

export const getUsers = () =>
  api(methods.get, `${url}?role=participant`).then(({ data: users }) => users)

export const getUserParticipations = (id) =>
  api(methods.get, `${url}/${id}/participations`).then(({ data: user }) => user)

export const getUserById = (id) =>
  api(methods.get, `${url}/${id}`).then(({ data: users }) => users)

export const getUserProfile = () =>
  api(methods.get, urlProfile).then(({ data: profile }) => profile)
