import { methods } from '../constants/apiMethods'
import { TRIVIA_ID } from '../constants/trivia'
import api from './api.helper'

const url = '/contests'

export const getTrivia = (data) =>
  api(
    methods.get,
    `${url}/${TRIVIA_ID}/question?difficulty_id=${data.difficulty_id}&category_id=${data.category_id}`
  ).then(({ data: questions }) => questions)

export const addTrivia = (data) =>
  api(methods.post, `${url}/${TRIVIA_ID}/participation`, data)
