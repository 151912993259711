export const routes = {
  auth: '/auth',
  dashboard: '/dashboard',
  events: '/events',
  prizes: '/prizes',
  participants: '/participants',
  categories: '/categories',
  difficulty: '/difficulties',
  devQuiz: '/devquiz',
  questions: '/questions',
}
