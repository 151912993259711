import './answer.scss'
import { InputText } from 'primereact/inputtext'
import React from 'react'
import { Checkbox } from 'primereact/checkbox'
import { Button } from 'primereact/button'
import PropTypes from 'prop-types'

const Answer = ({
  onDeleteAnswer = () => {},
  mandatory,
  curentAnswer,
  onChangeAnswer = () => {},
}) => (
  <div className="answer">
    <InputText
      value={curentAnswer.description}
      onChange={(e) =>
        onChangeAnswer({
          id: curentAnswer.id,
          description: e.target.value,
          correct: curentAnswer.correct || false,
        })
      }
      placeholder="Answer"
    />
    <div>
      <Checkbox
        inputId="cb2"
        className="answer__checkbox"
        value="San Francisco"
        checked={curentAnswer.correct}
        onChange={(e) =>
          onChangeAnswer({
            id: curentAnswer.id,
            description: curentAnswer.description,
            correct: e.checked,
          })
        }
      />
      <label htmlFor="cb2" className="answer__checkbox-label">
        Corect
      </label>
    </div>
    {!mandatory && (
      <Button
        onClick={() => onDeleteAnswer()}
        icon="pi pi-times"
        className="p-button-rounded p-button-text"
      />
    )}
  </div>
)

Answer.propTypes = {
  onDeleteAnswer: PropTypes.func,
  mandatory: PropTypes.bool,
  curentAnswer: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string.isRequired,
    correct: PropTypes.bool,
  }).isRequired,
  onChangeAnswer: PropTypes.func.isRequired,
}
Answer.defaultProps = {
  onDeleteAnswer: () => {},
  mandatory: false,
}

export { Answer }
