import './questions.scss'

import React, { useEffect, useRef, useState } from 'react'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toast } from 'primereact/toast'
import { MultiSelect } from 'primereact/multiselect'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { AppLayout } from '../../components/AppLayout/AppLayout.component'
import {
  addQuestion,
  deleteQuestion,
  getQuestion,
  getQuestions,
  updateQuestion,
} from '../../services/questions.service'
import { QuestionModal } from './questionModal/questionModal.component'
import { getDifficulties } from '../../services/difficulties.service'
import { getCategories } from '../../services/categories.service'

const Questions = () => {
  const toast = useRef(null)
  const [questions, setQuestions] = useState([])
  const [difficulties, setDifficulties] = useState(null)
  const [selectedDifficulties, setSelectedDifficulties] = useState([])
  const [categories, setCategories] = useState(null)
  const [selectedCategories, setSelectedCategories] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const filteredQuestions = questions
    .filter((x) =>
      x.description.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter((x) =>
      selectedDifficulties.length > 0
        ? selectedDifficulties.indexOf(x.difficulty.name) > -1
        : x
    )
    .filter((x) =>
      selectedCategories.length > 0
        ? selectedCategories.indexOf(x.category.name) > -1
        : x
    )
  const [isFiltersVisible, setIsFiltersVisible] = useState(false)
  const [isQuestionModalVisible, setIsQuestionModalVisible] = useState(false)
  const [selectedQuestion, setSelectedQuestion] = useState({
    id: null,
    description: '',
    difficulty_id: null,
    contest_id: null,
    category_id: null,
    answers_attributes: [],
  })

  const fetchAdditionalData = async () => {
    const fetchedDifficulties = await getDifficulties()
    setDifficulties(fetchedDifficulties)
    const fetchedCategoires = await getCategories()
    setCategories(fetchedCategoires)
  }

  const fetchQuestions = async () => {
    const fetchedQuestions = await getQuestions()
    setQuestions(fetchedQuestions)
  }

  const fetchQuestion = async (selectedQuestionID) => {
    const fetchedQuestion = await getQuestion(selectedQuestionID)
    const mappedQuestion = {
      id: fetchedQuestion.id,
      description: fetchedQuestion.description,
      difficulty_id: fetchedQuestion.difficulty_id,
      contest_id: fetchedQuestion.contest_id,
      category_id: fetchedQuestion.category_id,
      answers_attributes: fetchedQuestion.answers,
    }
    setSelectedQuestion(mappedQuestion)
    setIsQuestionModalVisible(true)
  }

  const handleModalHide = async () => {
    setIsQuestionModalVisible(false)
    setSelectedQuestion({
      id: null,
      description: '',
      difficulty_id: null,
      contest_id: null,
      category_id: null,
      answers_attributes: [],
    })
  }

  const handleDeleteQuestion = async () => {
    await deleteQuestion(selectedQuestion.id)
      .then(() => {
        toast.current.show({
          severity: 'success',
          summary: 'Deleted Succesfully',
          detail: 'Question deleted Succesfuly',
        })
      })
      .catch((err) => {
        toast.current.show({
          severity: 'error',
          summary: 'Delete Failed',
          detail: err,
        })
      })
    await fetchQuestions()
    handleModalHide()
  }

  const handleAddQuestion = async () => {
    if (selectedQuestion.id) {
      await updateQuestion(selectedQuestion.id, selectedQuestion)
        .then(() => {
          toast.current.show({
            severity: 'success',
            summary: 'Updated Succesfully',
            detail: 'Question updated Succesfully',
          })
        })
        .catch((err) => {
          toast.current.show({
            severity: 'error',
            summary: 'Update Failed',
            detail: err,
          })
        })
      await fetchQuestions()
      handleModalHide()
    } else {
      await addQuestion(selectedQuestion)
        .then(() => {
          toast.current.show({
            severity: 'success',
            summary: 'Added Succesfully',
            detail: 'Question added Succesfully',
          })
        })
        .catch((err) => {
          toast.current.show({
            severity: 'error',
            summary: 'Added Failed',
            detail: err.message,
          })
        })
      await fetchQuestions()
      handleModalHide()
    }
  }

  const handleDeleteConfirm = () => {
    confirmDialog({
      message: 'Do you want to delete this?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => handleDeleteQuestion(),
    })
  }
  const toggleFilters = () => {
    setIsFiltersVisible(!isFiltersVisible)
  }

  useEffect(() => {
    fetchQuestions()
    fetchAdditionalData()
  }, [])

  return (
    <AppLayout>
      <ConfirmDialog />
      {isQuestionModalVisible && (
        <QuestionModal
          isModalVisible={isQuestionModalVisible}
          onModalHide={handleModalHide}
          selectedQuestion={selectedQuestion}
          setSelectedQuestion={setSelectedQuestion}
          onQuestionAdd={handleAddQuestion}
          onQuestionDelete={handleDeleteConfirm}
        />
      )}
      <Toast ref={toast} />
      <div className="questions">
        <div className="questions__container">
          <div className="controlls">
            <div className="controlls__search">
              <InputText
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="controlls__search-bar"
                placeholder="Search"
              />
              <Button
                icon="pi pi-filter"
                className="p-button-rounded p-button-text"
                onClick={toggleFilters}
              />
            </div>
            <Button
              className="controlls__button"
              onClick={() => {
                setIsQuestionModalVisible(true)
              }}
            >
              Add Question
            </Button>
          </div>
          {isFiltersVisible && (
            <div className="controlls__filters">
              <MultiSelect
                className="controlls__filters__select"
                value={selectedDifficulties}
                onChange={(e) => setSelectedDifficulties(e.value)}
                optionLabel="name"
                optionValue="name"
                options={difficulties}
                placeholder="Filter By Difficulty"
              />
              <MultiSelect
                className="controlls__filters__select"
                value={selectedCategories}
                optionLabel="name"
                optionValue="name"
                onChange={(e) => {
                  setSelectedCategories(e.value)
                }}
                options={categories}
                placeholder="Filter By Category"
              />
            </div>
          )}
          <div className="table">
            <DataTable
              selectionMode="single"
              onSelectionChange={(e) => {
                fetchQuestion(e.value.id)
              }}
              metaKeySelection
              value={filteredQuestions}
              breakpoint="400px"
            >
              <Column field="description" sortable header="Question" />
              <Column field="category.name" sortable header="Category" />
              <Column field="difficulty.name" sortable header="Difficulty" />
            </DataTable>
          </div>
        </div>
      </div>
    </AppLayout>
  )
}

export { Questions }
