import React, { useRef } from 'react'
import { Button } from 'primereact/button'
import { Menu } from 'primereact/menu'
import { useNavigate } from 'react-router'
import { routes } from '../../../constants/routes'

const DevQuizMenu = () => {
  const navigate = useNavigate()
  const menu = useRef(null)
  const items = [
    {
      label: 'Contest',
      command: () => navigate(routes.devQuiz),
    },
    {
      label: 'Questions',
      command: () => navigate(routes.questions),
    },
    {
      label: 'Categories',
      command: () => navigate(routes.categories),
    },
    {
      label: 'Difficulties',
      command: () => navigate(routes.difficulty),
    },
  ]

  return (
    <>
      <Button
        label="DevQuiz Management"
        className="p-button-text"
        onClick={(event) => menu.current.toggle(event)}
      />
      <Menu model={items} popup ref={menu} />
    </>
  )
}

export { DevQuizMenu }
