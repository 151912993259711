import './EventsContentDialogEdit.scss'

import React from 'react'

import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import PropTypes from 'prop-types'

const EventsContentDialogEdit = ({
  isEditModalVisible,
  onEditModalHide = () => {},
  onEditButton = () => {},
  selectedEvent,
  editEvent = () => {},
}) => (
  <Dialog
    header="Edit Event Details"
    visible={isEditModalVisible}
    onHide={onEditModalHide}
    draggable={false}
    contentClassName="dialog-edit-event"
  >
    <span className="p-float-label">
      <InputText
        id="title"
        placeholder="Event Title"
        className="dialog-edit-event__input"
        defaultValue={selectedEvent.title}
        onChange={(e) => {
          editEvent({
            title: e.target.value,
            date: selectedEvent.date,
            max_points: selectedEvent.max_points,
            description: selectedEvent.description,
            id: selectedEvent.id,
          })
        }}
      />
      <label htmlFor="title">Event Title</label>
    </span>
    <span className="p-float-label">
      <Calendar
        id="time24"
        value={selectedEvent.date}
        className="dialog-edit-event__input"
        dateFormat="dd/mm/yy"
        hourFormat="24"
        onChange={(e) =>
          editEvent({
            title: selectedEvent.title,
            date: e.value,
            max_points: selectedEvent.max_points,
            description: selectedEvent.description,
            id: selectedEvent.id,
          })
        }
        showTime
      />
      <label htmlFor="date">Date</label>
    </span>
    <span className="p-float-label">
      <InputText
        id="max_points"
        className="dialog-edit-event__input"
        defaultValue={selectedEvent.max_points}
        onChange={(e) =>
          editEvent({
            title: selectedEvent.title,
            date: selectedEvent.date,
            max_points: e.target.value,
            description: selectedEvent.description,
            id: selectedEvent.id,
          })
        }
      />
      <label htmlFor="points">Points</label>
    </span>
    <span className="p-float-label">
      <InputTextarea
        defaultValue={selectedEvent.description}
        onChange={(e) =>
          editEvent({
            title: selectedEvent.title,
            date: selectedEvent.date,
            max_points: selectedEvent.max_points,
            description: e.target.value,
            id: selectedEvent.id,
          })
        }
        rows={5}
        cols={30}
      />
      <label htmlFor="description">Description</label>
    </span>
    <div className="dialog-edit-event__button">
      <Button
        className="p-button-sm"
        onClick={() => {
          onEditButton()
        }}
      >
        Save
      </Button>
    </div>
  </Dialog>
)

EventsContentDialogEdit.propTypes = {
  isEditModalVisible: PropTypes.bool.isRequired,
  onEditModalHide: PropTypes.func.isRequired,
  onEditButton: PropTypes.func.isRequired,
  selectedEvent: PropTypes.shape({
    title: PropTypes.string,
    id: PropTypes.number,
    date: PropTypes.instanceOf(Date),
    max_points: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    description: PropTypes.string,
  }).isRequired,
  editEvent: PropTypes.func.isRequired,
}

export default EventsContentDialogEdit
